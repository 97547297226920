<template>
	<v-menu
		v-if="notes.length"
		v-model="open"
		bottom
		origin="center center"
		:close-on-click="false"
		:close-on-content-click="false"
		transition="scale-transition"
	>
		<template v-slot:activator="{ on }">
			<v-btn
				color="primary"
				dark
				fab
				v-on="on"
				absolute
				style="top: 40px; left: 20px"
			>
				<v-icon dark>mdi-note</v-icon>
			</v-btn>
		</template>
		<v-card flat width="350">
			<v-card-title>
				<v-spacer></v-spacer>
				<v-btn @click="open = false" icon small
					><v-icon>mdi-minus</v-icon></v-btn
				>
			</v-card-title>
			<v-card-text>
				<p>Messages from the moderator will appear in the area below this message</p>
				<div v-html="notes"></div>
			</v-card-text>
		</v-card>
	</v-menu>
</template>

<script>
export default {
	name: "StickyNotes",
	data: () => {
		return {
			open: true,
			saving: false,
			saved: false,
		};
	},
	computed: {
		videoRoom() {
			return this.$store.state.videoRoom.data;
		},
		notes() {
			let notes = this.videoRoom.notes || "";
			notes = this.urlify(notes)
			notes = notes.split("\n").join("<br/>");
			return notes;
		},
	},
	methods: {
		urlify(text) {
			var urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
			return text.replace(urlRegex, function(url) {
				return '<a target="_blank" href="' + url + '">' + url + "</a>";
			});
		},
	},
	watch: {
		notes: {
			immediate: true, 
			handler(v){
				if (v) {
					if (!this.open) {
						this.open = true;
					}
				}
			}
		},
	},
};
</script>
