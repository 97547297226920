<template>
	<v-sheet flat>
		<template v-if="logged_in">
			<room
				v-if="canAccess"
				:roomName="id"

			></room>
			<v-card v-else color="lightest" class="rounded-xl" flat
				><v-card-text
					>Please wait to be admitted to the video conference</v-card-text
				></v-card
			>
		</template>
		<template v-else>
			<v-card color="lightest" class="rounded-xl" flat
				><v-card-text
					>Please
					<a @click="$root.$emit('openLogin')">log in</a></v-card-text
				></v-card
			>
		</template>
	</v-sheet>
</template>

<script>
import Room from "@c/video/Room.vue";
export default {
	name: "VideoContainer",
	props: {
		id: { type: String },
	},
	components: { Room },
	computed: {
		auth() {
			return this.$store.state.auth.data;
		},
		logged_in() {
			return this.auth.id || false;
		},
		videoRoom() {
			return this.$store.state.videoRoom.data;
		},
		canAccess() {
			return this.$store.getters["videoRoom/canAccess"];
		},
	},
	watch: {
		auth: {
			immediate: true,
			handler(v) {
				if (v) {
					this.$store.dispatch("videoRoom/start", this.id);
				}
			},
		},
		canAccess(v) {
			if (v) {
				this.$store.dispatch("videoRoom/enterConference");
			}
		},
	},
	destroyed() {
		this.$store.dispatch("videoRoom/leaveConference");
	},
};
</script>
