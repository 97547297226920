<template>
	<v-sheet>
		<v-sheet v-if="room">
			<sticky-notes></sticky-notes>
			<main-track></main-track>
		</v-sheet>
		<loading v-else></loading>
		<audio-tracks></audio-tracks>
		<v-navigation-drawer
			v-if="room"
			v-model="drawer"
			app
			id="video-navigation"
			:mini-variant="mini"
			permanent
			right
			style="z-index: 100"
		>
			<participant-list></participant-list>

			<template v-slot:append>
				<local-preview>
					<local-media-controls
						:room="room"
						@leaveRoom="leaveRoom"
					></local-media-controls>
				</local-preview>
			</template>
		</v-navigation-drawer>
	</v-sheet>
</template>
<script>
import Twilio from "twilio-video";
import Loading from "@c/ui/Loading";
import AudioTracks from "@c/video/AudioTracks";
import MainTrack from "@c/video/MainTrack";
import videoUtilities from "@/assets/videoUtilities.js";
import ParticipantList from "@c/video/ParticipantList.vue";
import LocalMediaControls from "@c/video/LocalMediaControls.vue";
import LocalPreview from "@c/video/LocalPreview.vue";
import StickyNotes from "@c/video/StickyNotes";

export default {
	name: "Room",
	props: {
		roomName: { type: String },
	},
	computed: {
		localIdentity() {
			return this.$store.state.auth.data.id;
		},
		localVideoTrack() {
			return this.$store.state.videoRoom.local_video_track;
		},
		screenShareTrack() {
			return this.$store.state.videoRoom.local_screen_track;
		},
		localAudioTrack() {
			return this.$store.state.videoRoom.local_audio_track;
		},
		main() {
			return this.videoRoom.main_track || {};
		},
		videoRoom() {
			return this.$store.state.videoRoom.data;
		},
		canAccess() {
			return this.$store.getters["videoRoom/canAccess"];
		},
	},
	components: {
		StickyNotes,
		ParticipantList,
		MainTrack,
		AudioTracks,
		LocalMediaControls,
		LocalPreview,
		Loading,
	},
	data: () => {
		return {
			video: true,
			audio: true,
			room: null,
			drawer: true,
			mini: false,
		};
	},
	methods: {
		async retrieveToken() {
			const self = this;
			if (!self.canAccess) {
				return;
			}
			return videoUtilities
				.getToken(this.roomName, this.localIdentity)
				.then((res) => {
					return res;
				});
		},
		participantConnected(participant) {
			this.$store.commit(
				"videoRoom/REMOTE_PARTICIPANT_ENTERS",
				participant.identity
			);
			this.subscribe(participant);
		},
		participantDisconnected(participant) {
			this.$store.commit(
				"videoRoom/REMOTE_PARTICIPANT_LEAVES",
				participant.identity
			);
			if (this.main.identity == participant.identity) {
				this.$store.commit("videoRoom/STOP_MAIN");
			}
		},
		handleEnabled(track, identity) {
			track.participant = identity;
			this.$store.commit("videoRoom/START_TRACK", track);
			track.on("disabled", () => {
				self.handleDisabled(track, identity);
			});
		},
		handleDisabled(track) {
			this.$store.commit("videoRoom/STOP_TRACK", track);
			this.$store.dispatch("videoRoom/unsetMain", track );
		},
		subscribe(participant) {
			const self = this;
			participant.on("trackUnpublished", (track) => {
				self.handleDisabled(track, participant.identity);
			});
			participant.tracks.forEach((publication) => {
				if (publication.isSubscribed) {
					const track = publication.track;
					self.handleEnabled(track, participant.identity);
				}
			});
			participant.on("trackSubscribed", (track) =>
				self.handleEnabled(track, participant.identity)
			);
		},
		startLocalVideo(track) {
			const self = this;
			self.$store.commit("videoRoom/START_LOCAL_VIDEO", track);
			track.on("disabled", () => {
				self.$store.commit("videoRoom/STOP_LOCAL_VIDEO", track);
			});
		},
		stopLocalVideo() {
			if (this.localVideoTrack) {
				this.room.localParticipant.unpublishTrack(this.localVideoTrack);
				this.$store.dispatch("videoRoom/stopLocalVideo");
			}
		},
		stopScreenshare() {
			if (this.screenShareTrack) {
				this.room.localParticipant.unpublishTrack(this.screenShareTrack);
				this.$store.dispatch("videoRoom/stopScreenshare");
			}
		},
		stopLocalAudio() {
			const self = this;
			let tracks = Array.from(
				self.room.localParticipant.audioTracks.values()
			);
			tracks.forEach((track) => {
				self.room.localParticipant.unpublishTrack(track.track);
			});
			self.$store.dispatch("videoRoom/stopLocalAudio");
		},
		start() {
			const self = this;
			self.retrieveToken().then((token) => {
				Twilio.connect(token, {
					name: self.roomName,
					audio: self.audio,
					video: self.video,
				}).then((room) => {
					
					room.localParticipant.videoTracks.forEach((track) => {
						this.startLocalVideo(track.track);
					});

					room.participants.forEach(self.participantConnected);
					room.on("participantConnected", self.participantConnected);
					room.on("participantDisconnected", self.participantDisconnected);
					room.once("disconnected", () =>
						room.participants.forEach(self.participantDisconnected)
					);
					self.room = room;
				});
			});
		},
		stopAll() {
			this.stopLocalAudio();
			this.stopLocalVideo();
			this.stopScreenshare();
		},
		leaveRoom() {
			this.stopAll();
			this.room.disconnect();
			this.$store.dispatch("videoRoom/leaveConference");
			this.$router.replace("/");
		},
	},

	created() {
		const self = this;
		window.addEventListener('beforeunload', () => {
			self.room.disconnect();
		});

		self.$root.$on("selectMainTrack", (content) => {
			self.$store.commit("videoRoom/MAKE_MAIN", content);
		});
		self.$root.$on("stopLocalAudio", () => {
			self.stopLocalAudio();
		});
		self.$root.$on("stopLocalVideo", () => {
			self.stopLocalVideo();
		});
		self.$root.$on("startLocalVideo", (track) => {
			self.startLocalVideo(track);
		});
		self.$root.$on("stopScreenshare", () => {
			self.stopScreenshare();
		});
		self.$root.$on("stopAll", () => {
			self.stopAll();
		});
		self.start();
	},
};
</script>
